<template>
  <!-- 续费Vip相关Model -->
  <el-dialog
    :title="title"
    :visible.sync="$store.state.vip.isVipModeStatus"
    :close-on-click-modal="false"
    center
    :customClass="
      showPaymentMethod
        ? 'isVipMode paymentMethodClass'
        : showConfirmPayment
        ? 'isVipMode confirmPaymentClass'
        : 'isVipMode'
    "
  >
    <div
      v-if="showAgree || showPaymentMethod || showPurchaseRecord"
      class="back"
      @click="back"
    >
      <div class="backButton">
        <span class="iconfont_Me icon-left iconStyle"></span>
      </div>
    </div>
    <div v-if="showConfirmPayment" class="back" @click="confirmPaymentBack">
      <div class="backButton">
        <span class="iconfont_Me icon-left iconStyle"></span>
      </div>
    </div>
    <div v-if="showOne">
      <div class="scroll-wrap" ref="scrollWrap">
        <div class="userInfo" v-if="isPopupCalledBySelf || !showGuide">
          <div class="head">
            <img
              v-if="$store.getters.userInfo.avatar"
              class="headerimg"
              :src="formartImageUrl($store.getters.userInfo.avatar)"
            />
          </div>
          <div class="info" v-if="userInfo">
            <div
              :class="userInfo.vipStatus ? 'vipName vName' : ''"
              :title="userInfo.name"
            >
              {{
                userInfo.name && userInfo.name.length > 10
                  ? userInfo.name.slice(0, 10) + ".."
                  : userInfo.name
              }}
            </div>
            <div class="email">{{ $store.getters.userInfo.uc_id }}</div>
            <div class="valid" v-if="userInfo.vipExpirationDate">
              {{ $t("membershipUntil") }}
              <span>{{ userInfo.vipExpirationDate | capitalize }}</span>
            </div>
          </div>
          <div class="purchaseRecord" @click="openPurchaseRecord">
            {{ $t("purchaseRecord") }}
            <i class="iconfont_Me icon-right iconStyle"></i>
          </div>
        </div>
        <div
          class="vip-content"
          :style="{
            'margin-top': !isPopupCalledBySelf && showGuide ? '10px' : '',
          }"
        >
          <div class="vip-icon">
            <el-image
              style="width: 20px; height: 17px;margin-right: 11px;"
              :src="require('../../../assets/images/pay/masonry.png')"
              fit="fill"
            >
            </el-image>
            <!-- {{ $t("encryptedMessaging") }} -->
            <span class="vip-tit">{{ $t("paidMember") }}</span>
          </div>
          <div class="cost">
            ￥120.00/{{ $t("year") }} ($20.00/{{ $t("year") }})
          </div>
        </div>
        <div class="membershipInterests">
          <div class="vline"></div>
          {{ $t("vipEquity") }}
        </div>
        <div class="mi-info">
          <div class="li">
            <el-image
              style="width: 36px; height: 36px;margin-right: 11px;"
              :src="require('../../../assets/images/vip/encryption.png')"
              fit="fill"
            >
            </el-image>
            {{ $t("encryptedMessaging") }}
          </div>
          <div class="hline"></div>
          <div class="li">
            <el-image
              style="width: 36px; height: 36px;margin-right: 11px;"
              :src="require('../../../assets/images/vip/cashWithdrawal.png')"
              fit="fill"
            >
            </el-image>
            {{ $t("quickProcessingOfWithdrawal") }}
          </div>
          <div class="hline"></div>
          <div class="li">
            <el-image
              style="width: 36px; height: 36px;margin-right: 11px;"
              :src="require('../../../assets/images/vip/post.png')"
              fit="fill"
            >
            </el-image>
            {{ $t("postPrioritization") }}
          </div>
          <div class="hline"></div>
          <div class="li">
            <el-image
              style="width: 36px; height: 36px;margin-right: 11px;"
              :src="require('../../../assets/images/vip/mechat.png')"
              fit="fill"
            >
            </el-image>
            {{ replaceMechati18n($t("meChat")) }}
          </div>
        </div>

        <div class="membershipInterests">
          <div class="vline"></div>
          {{ $t("vipExplain") }}
        </div>

        <div class="vip-info">
          <div class="tit">{{ $t("encryptedMessaging") }}</div>
          <div class="con">
            <p>{{ $t("instruction1") }}</p>
            <p>{{ $t("instruction2") }}</p>
            <p>{{ $t("instruction3") }}</p>
            <p>{{ $t("instruction4") }}</p>
          </div>
          <div class="tit" style="margin-top:13px">
            {{ $t("quickProcessingOfWithdrawal") }}
          </div>
          <div class="con">
            <p>{{ $t("quickProcessingOfWithdrawal1") }}</p>
          </div>
          <div class="tit" style="margin-top:13px">
            {{ $t("postPrioritization") }}
          </div>
          <div class="con">
            <p>{{ $t("postPrioritization1") }}</p>
          </div>
          <div class="tit" style="margin-top:13px">
            {{ replaceMechati18n($t("meChat")) }}
          </div>
          <div class="con">
            <p>{{ replaceMechati18n($t("meChat1")) }}</p>
            <p :style="{ 'margin-top': '13px' }">
              {{ replaceMechati18n($t("meChat2")) }}
            </p>
          </div>
        </div>
      </div>
      <template v-if="!showGuide">
        <div
          class="renewStepButton renewButtonHover"
          @click.stop="selectPaymentMethod"
        >
          {{ $t("renewal") }}
        </div>
      </template>
      <template v-else>
        <div
          class="nextStepButton commomButtonHover"
          @click.stop="goToMyMembership"
        >
          {{ $t("goToMyMembership") }}
        </div>
      </template>
    </div>
    <div v-if="showPurchaseRecord" :style="{ width: '100%' }">
      <div class="recordContent">
        <template v-if="purchaseRecords.length > 0">
          <div class="outerBox">
            <div
              class="leftBox innerBox"
              :style="{ height: `${purchaseRecords.length * 108}px` }"
            >
              <div
                class="point"
                v-for="(item, index) in purchaseRecords"
                :style="{ top: `${index * 108 + 37.5}px` }"
              ></div>
              <div
                class="line"
                v-for="(item, index) in purchaseRecords"
                :style="{ top: `${index * 108 + 50.5}px` }"
                v-if="index != purchaseRecords.length - 1"
              ></div>
            </div>
            <div class="rightBox innerBox">
              <div
                class="itemBox"
                v-for="(item, index) in purchaseRecords"
                :key="index"
              >
                <div
                  class="text_1 text_style"
                  :title="`Melinked ${$t('paidMember')}`"
                >
                  <span class="content_style"
                    >Melinked {{ $t("paidMember") }}</span
                  >
                  <span class="icon_style" v-if="item.inEffect">{{
                    $t("inEffect")
                  }}</span>
                </div>
                <div
                  class="text_2 text_style"
                  :title="`${item.start}-${item.end}`"
                >
                  {{ $t("expirationDate") }}: {{ item.start }}-{{ item.end }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <PlaceholderMap
            :placeholderType="2"
            :placeholderText="$t('noPurchaseHistory')"
          ></PlaceholderMap>
        </template>
      </div>
    </div>

    <!-- 会议使用协议 -->
    <div v-if="showAgree" class="two">
      <VipAgree />
    </div>

    <!-- 支付方式 -->
    <div v-if="showPaymentMethod" class="paymentMethod">
      <div class="tipsBox">
        {{ $t("purchaseMembership_tip_1") }}
        <span class="dateTips">{{ handleExpirationDate() }}</span>
      </div>
      <div class="price-content" v-if="radio == 'CNY'">
        <div>
          <div
            style="display:flex;align-items:baseline;font-size: 17px;color: #5b3717;"
          >
            ￥<span class="price">{{ vipPrices }}</span>
            <sub> /{{ $t("year") }}</sub>
          </div>
          <!-- YE ZFB WX -->
          <div v-if="radio2 == 'WX'" class="service-charge">
            {{ $t("weChatCharges") }} : ￥{{ vipPrices * 0.006 }}(0.6%)
          </div>
          <div v-if="radio2 == 'ZFB'" class="service-charge">
            {{ $t("alipayCharges") }} : ￥1.64(1.37%)
          </div>
        </div>
      </div>
      <div v-else class="price-content">
        <div
          style="display:flex;align-items:baseline;font-size: 17px;color: #5b3717;"
        >
          $ <span class="price">{{ vipPrices }}</span>
          <sub> /{{ $t("year") }}</sub>
        </div>
      </div>

      <!-- 选择币种 -->
      <div class="select-currency">
        <div class="box" @click="selectCNY">
          <el-radio v-model="radio" label="CNY"> </el-radio>
          <div class="flex">
            <el-image
              style="width: 42px; height: 42px"
              :src="require('../../../assets/images/pay/china.png')"
              fit="fill"
            ></el-image>
            CNY
          </div>
        </div>
        <div class="box" @click="selectUSD">
          <el-radio v-model="radio" label="USD"> </el-radio>
          <div class="flex">
            <el-image
              style="width: 42px; height: 42px"
              :src="require('../../../assets/images/pay/usa.png')"
              fit="fill"
            ></el-image>
            USD
          </div>
        </div>
      </div>
      <!-- 选址支付方式 -->
      <div class="pay-method">
        <!-- 余额 -->
        <div class="balance-pay" @click="selectYE">
          <div class="left">
            <el-image
              style="width: 25px;"
              :src="require('../../../assets/images/pay/ye.png')"
              fit="fill"
            >
            </el-image>
            {{ $t("balance") }}：
            <span v-if="radio == 'CNY'">￥{{ rmbassetsAbled }}</span>
            <span v-if="radio == 'USD'">${{ dolarassetsAbled }}</span>
          </div>
          <el-radio v-model="radio2" label="YE"> </el-radio>
        </div>
        <div class="line" v-if="radio == 'CNY'"></div>
        <!-- 微信 -->
        <!-- <div class="cny" v-if="radio == 'CNY'" @click="selectWX">
          <div class="flex">
            <el-image
              style="width: 26px;"
              :src="require('../../../assets/images/pay/weipay.png')"
              fit="fill"
            >
            </el-image>
          </div>
          <el-radio v-model="radio2" label="WX"> </el-radio>
        </div> -->
        <div class="line" v-if="radio == 'CNY'"></div>
        <!-- paypal -->
        <div class="usd" v-if="radio == 'USD'" @click="selectPP">
          <div class="flex">
            <el-image
              style="width: 95px;"
              :src="require('../../../assets/images/pay/pp.png')"
              fit="fill"
            >
            </el-image>
          </div>
          <el-radio v-model="radio2" label="paypal"> </el-radio>
        </div>
        <!-- 支付宝 -->
        <div class="usd" v-if="radio == 'CNY'" @click="selectZFB">
          <div class="flex">
            <el-image
              style="width: 26px;"
              :src="require('../../../assets/images/pay/zfpay.png')"
              fit="fill"
            >
            </el-image>
          </div>
          <el-radio v-model="radio2" label="ZFB"> </el-radio>
        </div>
      </div>
      <!-- <div class="balance" v-if="radio == 'CNY'">{{ $t('balance') }}：￥{{ rmbassetsAbled }}</div>
        <div class="balance" v-if="radio == 'USD'">{{ $t('balance') }}：${{ dolarassetsAbled }}</div> -->
      <div
        class="nextStepButton commomButtonHover"
        @click.stop="go2ConfirmPayment"
      >
        <i class="iconfont_Me icon-next iconStyle"></i>
      </div>
      <!-- 协议 -->
      <div class="agreement">
        {{ $t("click2Agree")
        }}<span class="vipagree" @click.stop="toAgree">{{
          $t("memberAgreement")
        }}</span>
      </div>
    </div>

    <!-- 输入密码 确认支付 -->
    <div v-if="showConfirmPayment" class="confirmPayment">
      <div class="title">{{ $t("enterYourPaymentPassword") }}</div>
      <form>
        <el-input
          prefix-icon="iconfont_Me icon-a-lujing13297"
          v-model="password1"
          show-password
          class="input-one"
        >
        </el-input>
      </form>
      <div
        class="nextStepButton"
        :class="disable ? 'disable' : ''"
        @click.stop="pay"
      >
        <i class="iconfont_Me icon-check iconStyle"></i>
      </div>
    </div>

    <form
      id="payment"
      class
      :action="payParams.submitUrl"
      method="post"
      target="_blank"
      hidden
    >
      <input
        type="text"
        :name="key"
        v-for="(val, key, index) in payParams"
        :value="val"
        :key="index"
      />
    </form>
  </el-dialog>
</template>
<script>
import VipAgree from "../vipMode/vipAgree.vue";
import { Base64 } from "js-base64";
import {
  getUserAuth_fetch,
  getAccountBalance_fetch,
  memberRecharge_fetch,
  getVipPurchaseRecords,
} from "@/api/newVersion/vip";
import { getPersonnelDetails } from "@/api/newVersion/personalCenter";
import QRCode from "qrcode";
import { mapMutations } from "vuex";
import PlaceholderMap from "@/newVersion/components/placeholderMap";
import moment from "moment";
export default {
  data() {
    return {
      showOne: true,
      showAgree: false, //协议
      showPaymentMethod: false, //支付方式
      showConfirmPayment: false, //确认支付
      title: this.$t("paidMember"),
      radio: "CNY",
      password1: null,
      dolarassetsAbled: 0, //美元余额
      rmbassetsAbled: 0, //人命币余额
      disable: true,
      userInfo: null,
      showOne: true,
      showAgree: false, //协议
      showPaymentMethod: false, //支付方式
      showConfirmPayment: false, //确认支付
      showInputPassword: false, //输入登录密码弹框
      loginPassword: "lxk.123456", //登录密码
      title: this.$t("paidMember"),
      radio: "CNY",
      radio2: "YE", //YE ZFB WX
      password1: "lxk.123456", //输入支付密码，确认支付
      dolarassetsAbled: 0, //美元余额
      rmbassetsAbled: 0, //人命币余额
      disable: true,
      loginPasswordDisable: true,
      types: ["password", "phone", "email", "question"],
      weixinpayQRcode: "",
      amountError: false,
      payParams: {},
      showPurchaseRecord: false, //购买记录
      purchaseRecords: [], //记录列表数据
      periodOfValidity: "", //有效期
      showGuide: false,
    };
  },
  components: {
    VipAgree,
    PlaceholderMap,
  },
  props: {},
  watch: {
    showPaymentMethod: {
      handler(val) {
        if (val) {
          this.checkBalanceIsSufficient();
        }
      },
    },
    password1: {
      handler(val) {
        if (val) {
          this.disable = false;
        } else {
          this.disable = true;
        }
      },
    },
  },
  mounted() {
    this.getPersonnelDetails();
    this.reset();
    this.showGuide = !this.isPopupCalledBySelf;
    // 如果是登录后返回的用户，直接进入第二步
    if (localStorage.getItem("joinMembership")) {
      this.selectPaymentMethod();
      localStorage.removeItem("joinMembership");
    }
  },
  filters: {
    capitalize: function(val) {
      val = val.toString();
      return val.substr(0, 4) + "-" + val.substr(4, 2) + "-" + val.substr(6, 2);
    },
  },

  beforeDestroy() {
    try {
      localStorage.removeItem("joinMembership");
      this.$store.commit("setIsPopupCalledBySelf", true);
    } catch (error) {}
  },
  computed: {
    vipPrices() {
      if (this.radio == "USD") {
        return "20.00";
      }
      if (this.radio == "CNY") {
        return "120.00";
      }
    },
    amount() {
      if (this.radio == "CNY") {
        if (this.radio2 === "WX") {
          return 120 * 0.006 + 120;
        }
        if (this.radio2 === "ZFB") {
          return 120 * 0.0135 + 120;
        }
        return 120;
      }
      if (this.radio == "USD") {
        return 20;
      }
    },
    // 交易渠道 2 MELINKED 3 支付宝 5 wx 7 paypal
    payMethod() {
      if (this.radio2 === "YE") {
        return 2;
      }
      if (this.radio2 === "ZFB") {
        return 3;
      }
      if (this.radio2 === "WX") {
        return 5;
      }
      if (this.radio2 === "paypal") {
        return 7;
      }
    },
    // 调用来源
    isPopupCalledBySelf() {
      return this.$store.state.vip.isPopupCalledBySelf;
    },
  },
  methods: {
    handleExpirationDate() {
      let date_end = moment(this.userInfo.vipExpirationDate, "YYYYMMDD");
      date_end = date_end.add(1, "year");
      return date_end.format("YYYY/MM/DD");
    },
    openPurchaseRecord() {
      this.showOne = false;
      this.title = this.$t("purchaseRecord");
      this.showPurchaseRecord = true;
      this.getPurchaseRecords();
    },
    async getPurchaseRecords() {
      const result = await getVipPurchaseRecords();
      const realTime = await this.getRealTime();
      if (result.code == 200) {
        this.purchaseRecords = result.data.data.map((data) => {
          let date_start = moment(data.expirationDate, "YYYYMMDD");
          let date_end = moment(data.expirationDate, "YYYYMMDD");
          let now = moment(realTime);
          date_start = date_start.subtract(1, "year");
          date_start = date_start.add(1, "day");
          return {
            start: date_start.format("YYYY/MM/DD"),
            end: date_end.format("YYYY/MM/DD"),
            inEffect: now.isBetween(date_start, date_end),
          };
        });
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    ...mapMutations([
      "setPaySucceTipsStatus",
      "setPayErrorTipsStatus",
      "setIsVipModeStatus",
      "setAuth",
    ]),
    //点击查看协议
    toAgree() {
      this.showOne = false;
      this.showPaymentMethod = false;
      this.showPurchaseRecord = false;
      this.title = this.$t("memberUseAgreement");
      this.showAgree = true;
    },
    // 去我的会员
    goToMyMembership() {
      this.showGuide = false;
    },
    //选中支付方式
    selectPaymentMethod() {
      this.title = this.$t("payment");
      this.showOne = false;
      this.showPaymentMethod = true;
      try {
        let today = moment();
        this.periodOfValidity = today.add(1, "years").format("YYYY-MM-DD");
      } catch (err) {}
    },
    //去到确认支付页面
    go2ConfirmPayment() {
      // 这里判断支付方式，如何是第三方支付，则不用输入支付密码，直接进入下一步,如果是余额支付则进入输入支付密码页面
      // 余额支付
      if (this.payMethod === 2) {
        // 余额不足，提示去充值
        if (this.radio === "CNY" && this.rmbassetsAbled - 120 < 0) {
          this.setPayErrorTipsStatus(true);
          return;
        }
        // 余额不足，提示去充值
        if (this.radio === "USD" && this.dolarassetsAbled - 20 < 0) {
          this.setPayErrorTipsStatus(true);
          return;
        }
        //检测支付密码
        this.checkPaymentPassword(() => {
          this.title = this.$t("confirmPayment");
          this.showOne = false;
          this.showPaymentMethod = false;
          this.showConfirmPayment = true;
        });
      }
      // 微信支付
      if (this.payMethod === 5) {
        this.pay_WX();
      }
      // 支付宝支付
      if (this.payMethod === 3) {
        this.pay_ZFB();
      }
      if (this.payMethod === 7) {
        this.pay_PP();
      }
    },
    //确认支付
    async pay() {
      if (!this.password1) {
        return false;
      }
      let params = {
        amount: this.amount,
        password: this.password1,
        walletType: this.radio === "CNY" ? 1 : 2,
        payMethod: this.payMethod,
      };
      if (localStorage.getItem("aesEncryptPrivateKey")) {
        params.privateKey = localStorage.getItem("aesEncryptPrivateKey");
      }
      if (localStorage.getItem("publicKey")) {
        params.publicKey = localStorage.getItem("publicKey");
      }
      let result = null;
      const body = this.getPostParams(
        params,
        this.$store.state.auth.auth.randomKey
      );
      // result = await memberRecharge(body)
      result = await memberRecharge_fetch(
        JSON.stringify(body),
        this.$store.state.auth.auth.token
      );
      if (result.code === 200) {
        this.setIsVipModeStatus(false);
        this.setPaySucceTipsStatus(true);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    async pay_PP() {
      let params = {
        amount: this.amount,
        walletType: this.radio === "CNY" ? 1 : 2,
        payMethod: this.payMethod,
      };
      if (localStorage.getItem("aesEncryptPrivateKey")) {
        params.privateKey = localStorage.getItem("aesEncryptPrivateKey");
      }
      if (localStorage.getItem("publicKey")) {
        params.publicKey = localStorage.getItem("publicKey");
      }
      let result = null;
      const body = this.getPostParams(
        params,
        this.$store.state.auth.auth.randomKey
      );
      result = await memberRecharge_fetch(
        JSON.stringify(body),
        this.$store.state.auth.auth.token
      );
      if (result.code === 200) {
        let decodeData = JSON.parse(Base64.decode(result.data));
        this.payMethod_PP(decodeData);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    async pay_ZFB() {
      let params = {
        amount: this.amount,
        walletType: this.radio === "CNY" ? 1 : 2,
        payMethod: this.payMethod,
      };

      if (localStorage.getItem("aesEncryptPrivateKey")) {
        params.privateKey = localStorage.getItem("aesEncryptPrivateKey");
      }
      if (localStorage.getItem("publicKey")) {
        params.publicKey = localStorage.getItem("publicKey");
      }
      let result = null;
      const body = this.getPostParams(
        params,
        this.$store.state.auth.auth.randomKey
      );
      result = await memberRecharge_fetch(
        JSON.stringify(body),
        this.$store.state.auth.auth.token
      );
      if (result.code === 200) {
        let decodeData = JSON.parse(Base64.decode(result.data));
        this.payParams = decodeData[0];
        this.payMethod_ZFB(decodeData);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    async pay_WX() {
      let params = {
        amount: this.amount,
        walletType: this.radio === "CNY" ? 1 : 2,
        payMethod: this.payMethod,
      };
      if (localStorage.getItem("aesEncryptPrivateKey")) {
        params.privateKey = localStorage.getItem("aesEncryptPrivateKey");
      }
      if (localStorage.getItem("publicKey")) {
        params.publicKey = localStorage.getItem("publicKey");
      }
      let result = null;
      const body = this.getPostParams(
        params,
        this.$store.state.auth.auth.randomKey
      );
      // result = await memberRecharge(body)
      result = await memberRecharge_fetch(
        JSON.stringify(body),
        this.$store.state.auth.auth.token
      );
      if (result.code === 200) {
        let decodeData = JSON.parse(Base64.decode(result.data));
        this.payMethod_WX(decodeData);
      }
    },
    payMethod_PP(decodeData) {
      this.payParams = decodeData[0];
      this.payParams.submitUrl = decodeData[0].paypalUrl;
      this.$nextTick(function() {
        document.forms.payment.submit();
        // 购买mechat成功回调
        const robotChatDescription = Object.assign(
          {},
          this.$store.state.globalShare.robotChatDescription,
          { visible: false }
        );
        this.$store.commit("setRobotChatDescription", robotChatDescription);
        this.setIsVipModeStatus(false);
      });
    },
    // 通知支付宝支付的后续处理
    payMethod_ZFB(payData) {
      // this.granterQRcode(payData[0].code_url, payData[0].rechargeId)
      this.$nextTick(function() {
        try {
          document.forms.payment.submit();
        } catch (error) {
          document.getElementById("payment").submit();
        }
        // 购买mechat成功回调
        const robotChatDescription = Object.assign(
          {},
          this.$store.state.globalShare.robotChatDescription,
          { visible: false }
        );
        this.$store.commit("setRobotChatDescription", robotChatDescription);
        this.setIsVipModeStatus(false);
      });
    },
    // 通过微信支付的后续处理
    payMethod_WX(payData) {
      this.granterQRcode(payData[0].code_url, payData[0].rechargeId);
      this.setIsVipModeStatus(false);
    },
    granterQRcode(text, rechargeId) {
      let self = this;
      QRCode.toDataURL(text)
        .then((url) => {
          self.amountError = false;
          self.weixinpayQRcode = url;
          self.$router.push({
            name: "payResultNotify",
            query: {
              QRcodeUrl: url,
              money: self.amount,
              rechargeId: rechargeId,
            },
          });
        })
        .catch((error) => {
          self.amountError = true;
          self.$message({
            type: "error",
            center: true,
            message: self.$t("amountErrorTip"),
          });
        });
    },

    //输入密码确认支付页的返回
    confirmPaymentBack() {
      this.showConfirmPayment = false;
      this.password1 = "";
      this.showPaymentMethod = true;
      this.title = this.$t("payment") || "支付";
    },
    back() {
      if (this.showAgree) {
        this.showAgree = false;
        this.showOne = false;
        this.showPaymentMethod = true;
      } else {
        this.showOne = true;
        this.showPaymentMethod = false;
      }
      this.showPurchaseRecord = false;
      this.title = this.$t("paidMember");
    },
    selectCNY() {
      this.radio = "CNY";
    },
    selectUSD() {
      this.radio = "USD";
      this.radio2 = "YE";
    },
    selectYE() {
      this.radio2 = "YE";
    },
    selectWX() {
      this.radio2 = "WX";
    },
    selectZFB() {
      this.radio2 = "ZFB";
    },
    selectPP() {
      this.radio2 = "paypal";
    },
    async checkBalanceIsSufficient() {
      let auth = await this.handlerGetAuth();
      // let result = await getAccountBalance(auth.token)
      let formData = new FormData();
      formData.append("token", auth.token);
      let result = await getAccountBalance_fetch(formData, auth.token);
      if (result.code == 200) {
        let res = JSON.parse(Base64.decode(result.data));
        this.rmbassetsAbled = res[1].assetsAbled;
        this.dolarassetsAbled = res[2].assetsAbled; //美元余额
      }
    }, // 获取新的token
    async handlerGetAuth() {
      let params = {
        userName: this.$store.getters.userInfo.id,
        password: this.$store.getters.userInfo.token,
      };
      let result = null;
      let base64Str = Base64.encode(JSON.stringify(params));
      let formData = new FormData();
      formData.append("user", base64Str);
      result = await getUserAuth_fetch("auth", formData);

      this.setAuth(result);
      return result;
    },
    //状态重置
    reset() {
      this.showOne = true;
      this.showAgree = false;
      this.showPaymentMethod = false;
      this.showConfirmPayment = false;
      this.showPurchaseRecord = false;
      this.userName = "";
      this.password1 = "";
      this.title = this.$t("paidMember");
      this.scrollToTop();
    },
    //拉取用户信息中的vip信息
    async getPersonnelDetails() {
      let result = await getPersonnelDetails(this.$store.getters.userInfo.id);
      if (result.code == 200) {
        this.userInfo = result.data.data;
      }
    },
    scrollToTop() {
      this.$refs.scrollWrap && (this.$refs.scrollWrap.scrollTop = 0);
    },
  },
};
</script>
<style lang="stylus" scoped>
.dialogHeader {
  width: 100%;
  height: 68px;
  box-sizing: border-box;
  padding: 17px 16px 0 0;

  .iconStyle {
    float: right;
    cursor: pointer;
    font-size: 16px;
    color: #CCCCCC;
  }
}
</style>
<style lang="scss" scoped>
:deep .isVipMode {
  width: 500px;
  // height: 528px;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    #ffefd5 0%,
    #ffffff,
    #f7f7f7,
    #f7f7f7,
    #ffffff
  );
  background: url("../../../assets/images/vip/vbg.png") no-repeat;
  background-size: 100% 100%;
  border-radius: 8px;

  .vName {
    font-size: 15px;
    font-weight: bold;
  }

  // 支付方式
  &.paymentMethodClass {
    width: 500px;
    // height: 499px;
    background: linear-gradient(180deg, #ffefd5, #ffffff 16%, #f5f5f5 35%);
    border-radius: 8px;
    .el-dialog__header {
      padding: 20px 20px 0 20px;
    }
    .el-dialog__body {
      padding-top: 0px;
    }
  }

  .backButton {
    width: 24px;
    height: 24px;
    float: left;
    cursor: pointer;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    background: #e8e8e8;

    .iconStyle {
      font-size: 12px;
      color: #000;
      font-weight: 700;
    }
  }

  .el-icon-close {
    font-size: 17px;
    color: #000;
  }

  .scroll-wrap {
    max-height: 60vh;
    overflow-y: auto;
    padding-bottom: 30px;
    max-width: 338px;

    &::-webkit-scrollbar {
      display: none;
      /* Chrome Safari */
    }
  }

  // 确认支付
  &.confirmPaymentClass {
    height: 325px;

    .el-dialog__body {
      height: calc(345px - 54px);
      padding: 50px 80px;
    }

    .confirmPayment {
      display: flex;
      flex-direction: column;

      .el-input__prefix {
        left: 10px;
      }

      .el-input__inner {
        padding-left: 30px;
      }

      .input-one {
        margin: 18px 0;
      }

      .icon-a-lujing13297,
      .el-input__clear {
        color: #000;
      }

      text-align: left;
      width: 100%;

      .title {
        font-size: 16px;
        color: #343434;
        letter-spacing: 0.8px;
      }

      .nextStepButton {
        margin-top: 40px;
        cursor: pointer;
      }
    }
  }

  .el-dialog__body {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .vipAgree {
      text-align: left;
    }

    .userInfo {
      display: flex;
      margin-bottom: 20px;
      position: relative;

      .head {
        width: 64px;
        height: 64px;
        margin-right: 22px;
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .email {
          font-size: 13px;
          color: #919191;
        }

        .valid {
          font-size: 12px;

          span {
            color: #ffb293;
          }
        }
      }

      .purchaseRecord {
        position: absolute;
        bottom: -16px;
        right: 0px;
        color: #8f8f8f;
        font-size: 12px;
        cursor: pointer;
        user-select: none;
        line-height: 16px;
        .iconStyle {
          font-size: 12px;
          color: #8f8f8f;
        }
      }
    }
    .recordContent {
      width: 100%;
      height: 324px;
      padding-top: 30px;
      box-sizing: content-box;
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.2);
        background: rgba(100, 100, 100, 0.2);
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.5);
      }
      .outerBox {
        margin: auto;
        width: 380px;
        overflow: hidden;
        min-height: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .innerBox {
          display: inline-block;
        }
        .leftBox {
          width: 20px;
          position: relative;
          .point {
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: #dca25b;
            position: absolute;
            left: 0;
          }
          .line {
            width: 1px;
            height: 91px;
            background: #d9d9d9;
            position: absolute;
            left: 4px;
          }
        }
        .rightBox {
          min-width: 0;
          flex: 1;
          flex-shrink: 0;
          min-height: 0;
          overflow: hidden;
          .itemBox {
            width: 360px;
            min-height: 84px;
            margin-bottom: 24px;
            border-radius: 7px;
            background: #ffffff;
            padding: 16px 20px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: space-between;
            .text_style {
              width: 100%;
              font-size: 16px;
              line-height: 22.4px;
              overflow: hidden;
              word-break: keep-all;
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }
            .text_1 {
              color: #333333;
              .content_style {
                flex: 1;
                min-width: 0;
              }
              .icon_style {
                flex-shrink: 0;
                display: inline-block;
                height: 20px;
                background: linear-gradient(
                  124.95deg,
                  #f7c988 13.3%,
                  #d4964e 93.65%
                );
                border-radius: 3px;
                padding: 0 4px;
                color: #ffffff;
                font-size: 12px;
                line-height: 20px;
              }
            }
            .text_2 {
              color: #808080;
            }
          }
        }
      }
    }
    .vip-content {
      text-align: center;
      width: 338px;
      height: 125px;
      background: linear-gradient(
        160deg,
        #fff5e6 0%,
        #fef1dd 48%,
        #f5d8a6 100%
      );
      background: url("../../../assets/images/pay/g1.png") no-repeat;
      background-size: 100% 100%;
      padding: 24px 0px;
      box-sizing: border-box;
      color: #1a1a1a;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .vipIcon {
        font-size: 16px;
        margin-right: 8px;
      }

      .vip-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
      }

      .vip-tit {
        font-size: 15px;
        font-weight: 700;
        color: #1a1a1a;
      }

      .cost {
        margin-top: 22px;
        padding: 8px 26px;
        width: fit-content;
        background: rgba($color: #fdd7a3, $alpha: 0.89);
        border-radius: 18px;
        font-size: 14px;
      }
    }

    .vip-info {
      margin-top: 24px;
      width: 338px;
      text-align: left;

      .tit {
        font-size: 15px;
        color: #1a1a1a;
        margin-bottom: 13px;
        word-break: keep-all;
      }

      .con {
        color: #808080;
        line-height: 22px;
        word-break: normal;
      }
    }

    .nextStepButton {
      height: 46px;
      margin-top: 30px;
      border-radius: 4px;
      background: #33cc66;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;

      &.disable {
        background: #d2d2d2;
      }

      .iconStyle {
        font-size: 24px;
        color: #ffffff;
      }
    }

    .renewStepButton {
      height: 46px;
      margin-top: 30px;
      border-radius: 4px;
      background: linear-gradient(124deg, #f7c988 13%, #d4964e 94%);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 15px;
    }

    .renewButtonHover {
      cursor: pointer !important;
      background: linear-gradient(124deg, #f7c988 13%, #d4964e 94%);
      transition: background 0.3s;
    }

    .agreement {
      text-align: left;
      margin-top: 8px;
      font-size: 13px;
      max-width: 338px;
    }

    .vipagree {
      color: #45d073;
      cursor: pointer;
    }

    .wechatTips {
      display: flex;
      height: auto;
      align-items: flex-start;
      padding: 0;
      font-size: 15px;
      font-family: "微软雅黑";
      color: #ff8a00;
      word-break: keep-all;
      line-height: 18px;
      margin: 10px 0 30px 0;
      .iconStyle {
        font-size: 18px;
        font-weight: 700;
        margin-right: 5px;
        vertical-align: -0.15em;
      }
    }
    .paymentMethod {
      max-width: 342px;
      .tipsBox {
        width: 100%;
        min-height: 48px;
        padding: 11px 0;
        font-size: 18px;
        color: #333333;
        line-height: 24px;
        .dateTips {
          color: #ff7038;
          margin-left: 3px;
        }
      }
      .price-content {
        width: 100%;
        height: 121px;
        // line-height: 121px;
        display: flex;
        align-items: center;
        padding-left: 26px;
        box-sizing: border-box;
        background: #f9d9a9;
        font-size: 16px;
        background: url("../../../assets/images/pay/p.png") no-repeat;
        background-size: cover;
        border-radius: 8px;
        box-shadow: 0px 6px 10px 0px rgba(226, 157, 76, 0.31);

        .service-charge {
          margin-top: 5px;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 20px;
          color: #5b3713;
          font-size: 12px;
          padding: 4px 12px;
          box-sizing: border-box;
        }

        .price {
          font-size: 32px;
          font-weight: bold;
          color: #5b3713;
        }
      }

      .select-currency {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        .box {
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        .flex {
          display: flex;
          align-items: center;
        }

        .el-radio {
          margin-right: 10px;
        }
      }

      .pay-method {
        width: 342px;
        // height: 154px;
        background: #ffffff;
        border-radius: 8px;
        overflow: hidden;
        margin-top: 20px;
        margin-bottom: 40px;

        .balance-pay {
          display: flex;
          height: 60px;
          justify-content: space-between;
          align-items: center;
          padding: 0 25px 0 16px;
          cursor: pointer;

          .left {
            display: flex;
            align-items: center;

            .el-image {
              margin-right: 8px;
            }
          }
        }

        .line {
          height: 1px;
          background: rgba(0, 0, 0, 0.1);
        }

        .cny,
        .usd {
          cursor: pointer;
          height: 60px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 25px 0 16px;
          box-sizing: border-box;

          .flex {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #9a9a9c;
          }
        }
      }

      // 余额
      .balance {
        padding: 15px;
        background: rgba($color: #e6f2ea, $alpha: 0.8);
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        color: #33cc66;
      }
    }

    .membershipInterests {
      margin-top: 14px;
      font-size: 15px;
      color: #333333;
      display: flex;
      align-items: center;
    }

    .vline {
      width: 4px;
      height: 17px;
      margin-right: 8px;
      background: #f1ba70;
    }

    .mi-info {
      background: #ffffff;
      border-radius: 7px;
      margin-top: 15px;
      padding: 0 16px;
      box-sizing: border-box;

      .li {
        padding: 12px 0;
        display: flex;
        align-items: center;
      }

      .hline {
        width: 100%;
        height: 1px;
        background: #e6e6e6;
        // border: 0.5px solid #e6e6e6;
      }
    }
  }

  .back {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
  }

  .two {
    width: 100%;
    max-height: 424px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
    }

    .nextStepButton {
      height: 46px;
      margin-top: 30px;
      border-radius: 4px;
      background: #33cc66;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

:deep .el-radio__label {
  display: none;
}

:deep .el-radio__inner {
  width: 18px;
  height: 18px;

  &::after {
    color: #fff;
    width: 0 !important;
    height: 0 !important;
    font-size: 14px;
    top: 4% !important;
    left: 10% !important;
    font-family: "element-icons" !important;
    content: "\E6DA";
  }
}
</style>
