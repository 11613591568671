var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.$store.state.vip.isVipModeStatus,
        "close-on-click-modal": false,
        center: "",
        customClass: _vm.showPaymentMethod
          ? "isVipMode paymentMethodClass"
          : _vm.showConfirmPayment
          ? "isVipMode confirmPaymentClass"
          : "isVipMode"
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(_vm.$store.state.vip, "isVipModeStatus", $event)
        }
      }
    },
    [
      _vm.showAgree || _vm.showPaymentMethod || _vm.showPurchaseRecord
        ? _c("div", { staticClass: "back", on: { click: _vm.back } }, [
            _c("div", { staticClass: "backButton" }, [
              _c("span", { staticClass: "iconfont_Me icon-left iconStyle" })
            ])
          ])
        : _vm._e(),
      _vm.showConfirmPayment
        ? _c(
            "div",
            { staticClass: "back", on: { click: _vm.confirmPaymentBack } },
            [
              _c("div", { staticClass: "backButton" }, [
                _c("span", { staticClass: "iconfont_Me icon-left iconStyle" })
              ])
            ]
          )
        : _vm._e(),
      _vm.showOne
        ? _c(
            "div",
            [
              _c("div", { ref: "scrollWrap", staticClass: "scroll-wrap" }, [
                _vm.isPopupCalledBySelf || !_vm.showGuide
                  ? _c("div", { staticClass: "userInfo" }, [
                      _c("div", { staticClass: "head" }, [
                        _vm.$store.getters.userInfo.avatar
                          ? _c("img", {
                              staticClass: "headerimg",
                              attrs: {
                                src: _vm.formartImageUrl(
                                  _vm.$store.getters.userInfo.avatar
                                )
                              }
                            })
                          : _vm._e()
                      ]),
                      _vm.userInfo
                        ? _c("div", { staticClass: "info" }, [
                            _c(
                              "div",
                              {
                                class: _vm.userInfo.vipStatus
                                  ? "vipName vName"
                                  : "",
                                attrs: { title: _vm.userInfo.name }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.userInfo.name &&
                                        _vm.userInfo.name.length > 10
                                        ? _vm.userInfo.name.slice(0, 10) + ".."
                                        : _vm.userInfo.name
                                    ) +
                                    "\n          "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "email" }, [
                              _vm._v(_vm._s(_vm.$store.getters.userInfo.uc_id))
                            ]),
                            _vm.userInfo.vipExpirationDate
                              ? _c("div", { staticClass: "valid" }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("membershipUntil")) +
                                      "\n            "
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("capitalize")(
                                          _vm.userInfo.vipExpirationDate
                                        )
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "purchaseRecord",
                          on: { click: _vm.openPurchaseRecord }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("purchaseRecord")) +
                              "\n          "
                          ),
                          _c("i", {
                            staticClass: "iconfont_Me icon-right iconStyle"
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "vip-content",
                    style: {
                      "margin-top":
                        !_vm.isPopupCalledBySelf && _vm.showGuide ? "10px" : ""
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "vip-icon" },
                      [
                        _c("el-image", {
                          staticStyle: {
                            width: "20px",
                            height: "17px",
                            "margin-right": "11px"
                          },
                          attrs: {
                            src: require("../../../assets/images/pay/masonry.png"),
                            fit: "fill"
                          }
                        }),
                        _c("span", { staticClass: "vip-tit" }, [
                          _vm._v(_vm._s(_vm.$t("paidMember")))
                        ])
                      ],
                      1
                    ),
                    _c("div", { staticClass: "cost" }, [
                      _vm._v(
                        "\n          ￥120.00/" +
                          _vm._s(_vm.$t("year")) +
                          " ($20.00/" +
                          _vm._s(_vm.$t("year")) +
                          ")\n        "
                      )
                    ])
                  ]
                ),
                _c("div", { staticClass: "membershipInterests" }, [
                  _c("div", { staticClass: "vline" }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("vipEquity")) + "\n      "
                  )
                ]),
                _c("div", { staticClass: "mi-info" }, [
                  _c(
                    "div",
                    { staticClass: "li" },
                    [
                      _c("el-image", {
                        staticStyle: {
                          width: "36px",
                          height: "36px",
                          "margin-right": "11px"
                        },
                        attrs: {
                          src: require("../../../assets/images/vip/encryption.png"),
                          fit: "fill"
                        }
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("encryptedMessaging")) +
                          "\n        "
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "hline" }),
                  _c(
                    "div",
                    { staticClass: "li" },
                    [
                      _c("el-image", {
                        staticStyle: {
                          width: "36px",
                          height: "36px",
                          "margin-right": "11px"
                        },
                        attrs: {
                          src: require("../../../assets/images/vip/cashWithdrawal.png"),
                          fit: "fill"
                        }
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("quickProcessingOfWithdrawal")) +
                          "\n        "
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "hline" }),
                  _c(
                    "div",
                    { staticClass: "li" },
                    [
                      _c("el-image", {
                        staticStyle: {
                          width: "36px",
                          height: "36px",
                          "margin-right": "11px"
                        },
                        attrs: {
                          src: require("../../../assets/images/vip/post.png"),
                          fit: "fill"
                        }
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("postPrioritization")) +
                          "\n        "
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "hline" }),
                  _c(
                    "div",
                    { staticClass: "li" },
                    [
                      _c("el-image", {
                        staticStyle: {
                          width: "36px",
                          height: "36px",
                          "margin-right": "11px"
                        },
                        attrs: {
                          src: require("../../../assets/images/vip/mechat.png"),
                          fit: "fill"
                        }
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.replaceMechati18n(_vm.$t("meChat"))) +
                          "\n        "
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "membershipInterests" }, [
                  _c("div", { staticClass: "vline" }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("vipExplain")) + "\n      "
                  )
                ]),
                _c("div", { staticClass: "vip-info" }, [
                  _c("div", { staticClass: "tit" }, [
                    _vm._v(_vm._s(_vm.$t("encryptedMessaging")))
                  ]),
                  _c("div", { staticClass: "con" }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("instruction1")))]),
                    _c("p", [_vm._v(_vm._s(_vm.$t("instruction2")))]),
                    _c("p", [_vm._v(_vm._s(_vm.$t("instruction3")))]),
                    _c("p", [_vm._v(_vm._s(_vm.$t("instruction4")))])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "tit",
                      staticStyle: { "margin-top": "13px" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("quickProcessingOfWithdrawal")) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("div", { staticClass: "con" }, [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("quickProcessingOfWithdrawal1")))
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "tit",
                      staticStyle: { "margin-top": "13px" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("postPrioritization")) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("div", { staticClass: "con" }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("postPrioritization1")))])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "tit",
                      staticStyle: { "margin-top": "13px" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.replaceMechati18n(_vm.$t("meChat"))) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("div", { staticClass: "con" }, [
                    _c("p", [
                      _vm._v(_vm._s(_vm.replaceMechati18n(_vm.$t("meChat1"))))
                    ]),
                    _c("p", { style: { "margin-top": "13px" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.replaceMechati18n(_vm.$t("meChat2"))) +
                          "\n          "
                      )
                    ])
                  ])
                ])
              ]),
              !_vm.showGuide
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "renewStepButton renewButtonHover",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.selectPaymentMethod.apply(
                              null,
                              arguments
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.$t("renewal")) + "\n      "
                        )
                      ]
                    )
                  ]
                : [
                    _c(
                      "div",
                      {
                        staticClass: "nextStepButton commomButtonHover",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.goToMyMembership.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("goToMyMembership")) +
                            "\n      "
                        )
                      ]
                    )
                  ]
            ],
            2
          )
        : _vm._e(),
      _vm.showPurchaseRecord
        ? _c("div", { style: { width: "100%" } }, [
            _c(
              "div",
              { staticClass: "recordContent" },
              [
                _vm.purchaseRecords.length > 0
                  ? [
                      _c("div", { staticClass: "outerBox" }, [
                        _c(
                          "div",
                          {
                            staticClass: "leftBox innerBox",
                            style: {
                              height: `${_vm.purchaseRecords.length * 108}px`
                            }
                          },
                          [
                            _vm._l(_vm.purchaseRecords, function(item, index) {
                              return _c("div", {
                                staticClass: "point",
                                style: { top: `${index * 108 + 37.5}px` }
                              })
                            }),
                            _vm._l(_vm.purchaseRecords, function(item, index) {
                              return index != _vm.purchaseRecords.length - 1
                                ? _c("div", {
                                    staticClass: "line",
                                    style: { top: `${index * 108 + 50.5}px` }
                                  })
                                : _vm._e()
                            })
                          ],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "rightBox innerBox" },
                          _vm._l(_vm.purchaseRecords, function(item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "itemBox" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text_1 text_style",
                                    attrs: {
                                      title: `Melinked ${_vm.$t("paidMember")}`
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "content_style" },
                                      [
                                        _vm._v(
                                          "Melinked " +
                                            _vm._s(_vm.$t("paidMember"))
                                        )
                                      ]
                                    ),
                                    item.inEffect
                                      ? _c(
                                          "span",
                                          { staticClass: "icon_style" },
                                          [_vm._v(_vm._s(_vm.$t("inEffect")))]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "text_2 text_style",
                                    attrs: {
                                      title: `${item.start}-${item.end}`
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("expirationDate")) +
                                        ": " +
                                        _vm._s(item.start) +
                                        "-" +
                                        _vm._s(item.end) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ]
                  : [
                      _c("PlaceholderMap", {
                        attrs: {
                          placeholderType: 2,
                          placeholderText: _vm.$t("noPurchaseHistory")
                        }
                      })
                    ]
              ],
              2
            )
          ])
        : _vm._e(),
      _vm.showAgree
        ? _c("div", { staticClass: "two" }, [_c("VipAgree")], 1)
        : _vm._e(),
      _vm.showPaymentMethod
        ? _c("div", { staticClass: "paymentMethod" }, [
            _c("div", { staticClass: "tipsBox" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("purchaseMembership_tip_1")) +
                  "\n      "
              ),
              _c("span", { staticClass: "dateTips" }, [
                _vm._v(_vm._s(_vm.handleExpirationDate()))
              ])
            ]),
            _vm.radio == "CNY"
              ? _c("div", { staticClass: "price-content" }, [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "baseline",
                          "font-size": "17px",
                          color: "#5b3717"
                        }
                      },
                      [
                        _vm._v("\n          ￥"),
                        _c("span", { staticClass: "price" }, [
                          _vm._v(_vm._s(_vm.vipPrices))
                        ]),
                        _c("sub", [_vm._v(" /" + _vm._s(_vm.$t("year")))])
                      ]
                    ),
                    _vm.radio2 == "WX"
                      ? _c("div", { staticClass: "service-charge" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("weChatCharges")) +
                              " : ￥" +
                              _vm._s(_vm.vipPrices * 0.006) +
                              "(0.6%)\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm.radio2 == "ZFB"
                      ? _c("div", { staticClass: "service-charge" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("alipayCharges")) +
                              " : ￥1.64(1.37%)\n        "
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              : _c("div", { staticClass: "price-content" }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "baseline",
                        "font-size": "17px",
                        color: "#5b3717"
                      }
                    },
                    [
                      _vm._v("\n        $ "),
                      _c("span", { staticClass: "price" }, [
                        _vm._v(_vm._s(_vm.vipPrices))
                      ]),
                      _c("sub", [_vm._v(" /" + _vm._s(_vm.$t("year")))])
                    ]
                  )
                ]),
            _c("div", { staticClass: "select-currency" }, [
              _c(
                "div",
                { staticClass: "box", on: { click: _vm.selectCNY } },
                [
                  _c("el-radio", {
                    attrs: { label: "CNY" },
                    model: {
                      value: _vm.radio,
                      callback: function($$v) {
                        _vm.radio = $$v
                      },
                      expression: "radio"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "42px", height: "42px" },
                        attrs: {
                          src: require("../../../assets/images/pay/china.png"),
                          fit: "fill"
                        }
                      }),
                      _vm._v("\n          CNY\n        ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "box", on: { click: _vm.selectUSD } },
                [
                  _c("el-radio", {
                    attrs: { label: "USD" },
                    model: {
                      value: _vm.radio,
                      callback: function($$v) {
                        _vm.radio = $$v
                      },
                      expression: "radio"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "42px", height: "42px" },
                        attrs: {
                          src: require("../../../assets/images/pay/usa.png"),
                          fit: "fill"
                        }
                      }),
                      _vm._v("\n          USD\n        ")
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "pay-method" }, [
              _c(
                "div",
                { staticClass: "balance-pay", on: { click: _vm.selectYE } },
                [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "25px" },
                        attrs: {
                          src: require("../../../assets/images/pay/ye.png"),
                          fit: "fill"
                        }
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("balance")) +
                          "：\n          "
                      ),
                      _vm.radio == "CNY"
                        ? _c("span", [
                            _vm._v("￥" + _vm._s(_vm.rmbassetsAbled))
                          ])
                        : _vm._e(),
                      _vm.radio == "USD"
                        ? _c("span", [
                            _vm._v("$" + _vm._s(_vm.dolarassetsAbled))
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("el-radio", {
                    attrs: { label: "YE" },
                    model: {
                      value: _vm.radio2,
                      callback: function($$v) {
                        _vm.radio2 = $$v
                      },
                      expression: "radio2"
                    }
                  })
                ],
                1
              ),
              _vm.radio == "CNY"
                ? _c("div", { staticClass: "line" })
                : _vm._e(),
              _vm.radio == "CNY"
                ? _c("div", { staticClass: "line" })
                : _vm._e(),
              _vm.radio == "USD"
                ? _c(
                    "div",
                    { staticClass: "usd", on: { click: _vm.selectPP } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "95px" },
                            attrs: {
                              src: require("../../../assets/images/pay/pp.png"),
                              fit: "fill"
                            }
                          })
                        ],
                        1
                      ),
                      _c("el-radio", {
                        attrs: { label: "paypal" },
                        model: {
                          value: _vm.radio2,
                          callback: function($$v) {
                            _vm.radio2 = $$v
                          },
                          expression: "radio2"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.radio == "CNY"
                ? _c(
                    "div",
                    { staticClass: "usd", on: { click: _vm.selectZFB } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "26px" },
                            attrs: {
                              src: require("../../../assets/images/pay/zfpay.png"),
                              fit: "fill"
                            }
                          })
                        ],
                        1
                      ),
                      _c("el-radio", {
                        attrs: { label: "ZFB" },
                        model: {
                          value: _vm.radio2,
                          callback: function($$v) {
                            _vm.radio2 = $$v
                          },
                          expression: "radio2"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c(
              "div",
              {
                staticClass: "nextStepButton commomButtonHover",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.go2ConfirmPayment.apply(null, arguments)
                  }
                }
              },
              [_c("i", { staticClass: "iconfont_Me icon-next iconStyle" })]
            ),
            _c("div", { staticClass: "agreement" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("click2Agree"))),
              _c(
                "span",
                {
                  staticClass: "vipagree",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.toAgree.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("memberAgreement")))]
              )
            ])
          ])
        : _vm._e(),
      _vm.showConfirmPayment
        ? _c("div", { staticClass: "confirmPayment" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("enterYourPaymentPassword")))
            ]),
            _c(
              "form",
              [
                _c("el-input", {
                  staticClass: "input-one",
                  attrs: {
                    "prefix-icon": "iconfont_Me icon-a-lujing13297",
                    "show-password": ""
                  },
                  model: {
                    value: _vm.password1,
                    callback: function($$v) {
                      _vm.password1 = $$v
                    },
                    expression: "password1"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "nextStepButton",
                class: _vm.disable ? "disable" : "",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.pay.apply(null, arguments)
                  }
                }
              },
              [_c("i", { staticClass: "iconfont_Me icon-check iconStyle" })]
            )
          ])
        : _vm._e(),
      _c(
        "form",
        {
          attrs: {
            id: "payment",
            action: _vm.payParams.submitUrl,
            method: "post",
            target: "_blank",
            hidden: ""
          }
        },
        _vm._l(_vm.payParams, function(val, key, index) {
          return _c("input", {
            key: index,
            attrs: { type: "text", name: key },
            domProps: { value: val }
          })
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }